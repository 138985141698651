.container {
    display: flex;
    flex-direction: column;

    --transition-duration: 0.25s;
    --transition-fn: linear;
}

.header {
    height: var(--toolbar-height);
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: var(--border);
}

.headerIcon {
    transform: rotate(0deg);
    transition: transform var(--transition-duration) var(--transition-fn);
}

.expanded .headerIcon {
    transform: rotate(90deg);
}

.headerText {
    flex: 1;
}

.content {
    /*transform: scaleY(0);*/
    /*transform-origin: top;*/
    /*transition: transform var(--transition-duration) var(--transition-fn);*/
    transition: height var(--transition-duration) var(--transition-fn);
    overflow: hidden;
}

.expanded .content {
    /*transform: scaleY(1);*/
}
