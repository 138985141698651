.container {
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: var(--bg-color);
    display: grid;
    grid-template-areas:
        'header header'
        'sidebar toolbar'
        'sidebar viewer';
    grid-template-rows: 0 minmax(0, auto) minmax(0, 1fr);
    grid-template-columns: 0 minmax(0, 1fr);
}

.header {
    grid-area: header;
    height: var(--header-height);
    border-bottom: var(--border);
    visibility: hidden;
}

.toolbar {
    grid-area: toolbar;
    height: var(--toolbar-height);
    border-bottom: var(--border);
}

.sidebar {
    position: absolute;
    left: 0;
    top: 0;
    width: var(--sidebar-width);
    height: 100%;
    border-right: var(--border);
}

.sidebar.hidden {
    width: 0;
}

.overlay {
    position: fixed;
    left: var(--sidebar-width);
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0.4;
    background-color: black;
}

.viewer {
    grid-area: viewer;
}

@media only screen and (min-width: 1021px) {
    .header {
        visibility: visible;
    }

    .sidebar,
    .overlay {
        top: var(--header-height);
    }

    .container {
        grid-template-rows: minmax(0, auto) minmax(0, auto) minmax(0, 1fr);
        grid-template-columns: 0 minmax(0, 1fr);
    }
}

@media only screen and (min-width: 1200px) {
    .sidebar {
        position: static;
        grid-area: sidebar;
    }

    .container {
        grid-template-rows: minmax(0, auto) minmax(0, auto) minmax(0, 1fr);
        grid-template-columns: minmax(0, auto) minmax(0, 1fr);
    }
}
