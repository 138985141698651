.container {
    --spacing: 0.5em;

    overflow: hidden scroll;
    background-color: var(--bg-color);
}

.toolbar {
    border-bottom: var(--border);
    justify-content: flex-end;
    height: var(--toolbar-height);
}

.modelItemContainer {
    display: flex;
    flex-direction: row;
    height: var(--model-item-height);
    line-height: 1;
    background-color: var(--bg-color-dark);
    border-bottom: var(--border);
    border-right: 4px solid transparent;
    cursor: pointer;
    padding: 0.2em 0;
    font-size: 0.9rem;
}

.modelItemContainer > * {
    margin-left: var(--spacing);
}

.modelItemContainer.selected {
    border-right: 4px solid var(--active-color);
}

.modelItemImage {
    width: calc(var(--model-item-height) - var(--border-size));
}

.modelItemTextContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.modelItemTextContainer > :last-child {
    overflow: hidden;
}

.expander {
    border-bottom: var(--border);
}
