.container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 0 1em;
    font-size: 1.5rem;
    color: var(--header-text-color);
}

.logo {
    height: var(--header-logo-height);
}

.container > :not(:first-child) {
    margin-left: 0.5em;
}
