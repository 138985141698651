.container {
    display: flex;
    flex-direction: row;
    background-color: var(--bg-color);
}

.button {
    cursor: pointer;
}

.button > svg {
    fill: var(--color-light);
}
