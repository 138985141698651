:root {
    box-sizing: border-box;
    font-size: 16px;

    --sidebar-width: 280px;
    --header-height: 60px;
    --header-logo-height: 44px;
    --toolbar-height: 40px;
    --model-item-height: 80px;
    --color-light: #a7a7a7;
    --bg-color: white;
    --bg-color-dark: #e6e6e6;
    --border-size: 1px;
    --border: solid #b8b8b8 var(--border-size);
    --active-color: #7191d7;
    --header-text-color: #606062;
}

*,
::before,
::after {
    box-sizing: inherit;
}

html {
    height: 100%;
}

html * {
    font-family: Avenir, Helvetica, Arial, sans-serif;
}

body {
    position: fixed; /* Prevent bouncing effect in Safari */
    width: 100%;
    height: 100%;
    margin: 0;
}

#root {
    width: 100%;
    height: 100%;
}
